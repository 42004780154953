<template>
  <div class="tecnology-contaner">
    <Header></Header>
    <Nav></Nav>
    <!-- 技术中心 start -->
    <el-row class="tech-box">
      <el-row class="contaner">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <!-- <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>技术中心</el-breadcrumb-item>
          </el-breadcrumb> -->
          <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              ><span class="c3">技术中心</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
        <!-- 内容区域 start -->
        <el-row class="technology-area">
          <!-- 搜索框 -->
          <el-row class="input-box">
            <el-input
              v-model="input"
              class="input-with-select"
              @change="toSearch"
            >
              <el-button slot="append" icon="el-icon-search" @click="toSearch"
                >搜索</el-button
              >
            </el-input>
          </el-row>
          <!-- tab1 -->
          <!-- <el-row class="tab1">
            <ul>
              <li
                v-for="(item, index) in oneSort"
                :key="index"
                @click="changeTab1Index(index, item)"
              >
                <p :class="{ active1: tab1Index == index }">
                  {{ item.categoryTitle }}
                </p>
                <img
                  v-if="tab1Index == index"
                  src="@/assets/images/login/hengxian.png"
                  alt=""
                />
              </li>
            </ul>
          </el-row> -->
          <!-- tab2 -->
          <!-- <el-row class="tab2">
            <ul>
              <li
                v-for="(item, index) in twoSort"
                :key="index"
                :class="{ active1: tab2Index == index }"
                @click="changeTab2Index(index, item)"
              >
                <p>{{ item.categoryTitle }}</p>
              </li>
            </ul>
          </el-row> -->
          <!-- s2tab下拉选择 -->
          <div class="searchBox">
            <!-- 系统类目: -->
            <el-select
              v-model="systemCate"
              placeholder="系统类目"
              clearable
              :popper-append-to-body="false"
              popper-class="select-popper"
            >
              <el-option
                v-for="item in systemCateData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 系统类目:结束 -->
            <!-- 品牌类目: -->
            <el-select
              v-model="brandCate"
              placeholder="品牌类目"
              clearable
              :popper-append-to-body="false"
              popper-class="select-popper"
            >
              <el-option
                v-for="item in brandCateData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 品牌类目:结束 -->
            <!-- 故障部位: -->
            <el-select
              v-model="faultCate"
              placeholder="故障部位"
              clearable
              :popper-append-to-body="false"
              popper-class="select-popper"
            >
              <el-option
                v-for="item in faultCateData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 故障部位:结束 -->
            <!-- 机型类目: -->
            <el-select
              v-model="typeCate"
              placeholder="机型类目"
              clearable
              :popper-append-to-body="false"
              popper-class="select-popper"
            >
              <el-option
                v-for="item in typeCateData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 机型类目:结束 -->
          </div>
          <!-- 案例 -->
          <div class="case" v-if="atriceList.length">
            <div
              style="display: inline-block"
              v-for="(item, index) in atriceList"
              :key="index"
              @click="handleArticleClick(item)"
            >
              <el-row class="item" type="flex" align="middle">
                <div class="img-box">
                  <img :src="item.mainImg" alt="" />
                  <div class="banner-box" v-if="item.releaseType === 1">
                    <img
                      class="banner-img"
                      src="@/assets/images/home/video.png"
                      alt=""
                    />
                  </div>
                </div>
                <el-row class="contant-box">
                  <el-row class="box">
                    <p class="contant">
                      {{ item.essayTitle }}
                    </p>
                    <p class="date">{{ item.createTime }}</p>
                  </el-row>
                </el-row>
              </el-row>
            </div>
          </div>
          <el-empty
            style="width: 100%"
            v-else
            description="暂无数据"
          ></el-empty>
        </el-row>
        <!-- 内容区域 end -->
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listTotal"
          :page-size="pageSize"
          :current-page="page"
          @current-change="pageChange"
          @prev-click="prevChange"
          @next-click="nextChange"
        >
        </el-pagination>
      </el-row>
    </el-row>
    <!-- 技术中心 end -->

    <Footer></Footer>

    <tell-us bottom="266px"></tell-us>

    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  name: "YoufujichuangTecnology",
  components: {
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      typeCate: "", //机型类目
      typeCateData: [],
      faultCate: "", //故障部位
      faultCateData: [],
      brandCate: "", //品牌类目
      brandCateData: [],
      systemCate: "", //系统类目
      systemCateData: [],
      assderVisible: false, //客服弹窗
      input: "", //搜索内容
      tab1Index: 0, //tab1激活项
      tab2Index: 0, //tab2激活项

      // 分页相关数据
      page: 1,
      pageSize: 10,
      listTotal: 0,

      // 分类
      sortId: "", //一级分类对应id 作为参数传递给二级分类  获取对应二级分类
      sortTwoId: "", //二级分类对应id
      oneSort: [],
      twoSort: [],

      // 文章列表
      atriceList: [],
    };
  },

  mounted() {},
  created() {
    // 查询文章一级类目
    this.getOneSort();
  },

  methods: {
    // tab1切换
    changeTab1Index(index, item) {
      // console.log("分类二index", this.tab2Index);

      this.tab1Index = index;
      this.tab2Index = 0;
      // console.log("item", item);
      this.sortId = item.id;
      // 查询文章二级类目
      this.getTwoSort();
    },
    // tab2切换
    changeTab2Index(index, item) {
      this.tab2Index = index;
      this.sortTwoId = item.id;
      console.log(this.sortId, "    ", this.sortTwoId);

      // 查询文章二级类目
      this.getArticeListApi();
    },
    // 查询文章二级类目
    getTwoSort() {
      let paramData = {
        categoryType: 1,
        parentId: this.sortId,
      };
      this.$API
        .getGoodSort(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.twoSort = res.data;
            // 默认二级分类id
            this.sortTwoId = this.twoSort[0].id;
            console.log(this.sortId, "    ", this.sortTwoId);
            // 文章-分页列表查询
            this.getArticeListApi();
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询文章一级类目
    getOneSort() {
      let paramData = {
        categoryType: 1,
        parentId: 0,
      };
      this.$API
        .getGoodSort(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.oneSort = res.data;
            this.sortId = res.data[0].id;
            // 查询文章二级类目
            this.getTwoSort();
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 底部的列表项点击跳转操作
    handleArticleClick(item) {
      // 发布类型（1-视频类型 2-文章类型）
      let urlObj = {
        1: "/case-video?id=" + item.id,
        2: "/case-detail?id=" + item.id,
      };
      this.$router.push(urlObj[item.releaseType]);
    },
    // 文章-分页列表查询
    getArticeListApi() {
      let paramData;
      if (this.input) {
        // 有搜索内容
        paramData = {
          pageNo: this.page,
          pageSize: this.pageSize,
          categoryOneId: this.sortId,
          categoryTwoId: this.sortTwoId,
          title: this.input,
        };
      } else {
        // 无搜索内容
        paramData = {
          pageNo: this.page,
          pageSize: this.pageSize,
          categoryOneId: this.sortId,
          categoryTwoId: this.sortTwoId,
        };
      }
      // let paramData = {
      //   pageNo: this.page,
      //   pageSize: this.pageSize,
      //   categoryOneId: this.sortId,
      //   categoryTwoId: this.sortTwoId,
      //   title: this.input,
      // };
      // console.log(paramData);
      // return;
      this.$API
        .getArticeListApi(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.atriceList = [];
            this.atriceList = res.data.records;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.getArticeListApi();
    },
    prevChange() {},
    nextChange() {},

    // 文章-分页列表查询
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 去搜索
    toSearch() {
      this.getArticeListApi();
    },
  },
};
</script>

<style lang="scss">
.el-input__suffix {
  top: 1px !important;
}
.searchBox {
  padding: 24px;
  display: flex;
  justify-content: space-around;
  // justify-content: space-between;
}

.tecnology-contaner {
  // 分頁
  .el-pagination {
    text-align: center;
    margin-top: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .tech-box {
    min-width: 1200px;
    width: 100%;
    padding-bottom: 40px;
    border-top: 1px solid #ed1b23;
    background: #f8f8f8;
    .contaner {
      background: #f8f8f8;
      // border-top: 1px solid #ed1b23;
    }
  }
  .nav-box {
    padding-top: 36px;
    .pos {
      color: #333333;
      font-size: 16px;
    }
  }
  .technology-area {
    width: 100%;
    padding-top: 24px;
    background-color: #fff;
    margin-top: 32px;
  }
  .case {
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    padding-bottom: 0px;
    margin-top: 24px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      .img-box {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 22px #C0C0C0 !important;
        .banner-box {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 120px;
          height: 120px;
          background: #000000;
          border-radius: 16px 16px 16px 16px;
          opacity: 0.3;
          display: flex;
          justify-content: center;
          align-items: center;
          .banner-img {
            width: 32px;
            height: 32px;
            margin-right: 0px !important;
          }
        }
      }
      width: 540px;
      display: inline-block;
      margin-bottom: 32px;
      cursor: pointer;
      &:hover img{
        width: 200px;
        height: 200px;
      }
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }

      &:nth-child(2n) {
        margin-right: 40px;
      }
      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        vertical-align: top;
        transition: all 0.2s;
      }
      .contant-box {
        display: inline-block;
      }
      .box {
        width: 400px;
        height: 120px;
        display: block;
        .contant {
          padding-top: 10px;
          @include dot(2);
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
        .date {
          position: absolute;
          bottom: 10px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
  // 搜索
  .el-select .el-input {
    width: 130px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .input-box {
    width: 620px;
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    border: 2px solid #ed1b23;
    margin: 0 auto;
  }
  .el-input__inner {
    height: 32px !important;
  }
  .el-input-group__append {
    width: 90px;
    height: 38px;
    color: #ffffff;
    background: linear-gradient(166deg, #ed1b23 0%, #f15f64 100%);
    border-radius: 0px 4px 4px 0px;
    position: relative;
    top: -3px;
    right: -2px;
  }
  .el-input__inner:hover {
    border-color: transparent;
  }
  .el-input__inner:focus {
    border-color: transparent;
  }
  // tab1
  .tab1 {
    margin-top: 40px;
    margin-left: 40px;
    height: 42px;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    li {
      text-align: center;
      font-size: 16px;
      float: left;
      color: #666666;
      margin-right: 40px;
      img {
        margin-top: 14px;
        width: 32px;
        height: 4px;
      }
    }
    .active1 {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
    }
  }
  // tab2
  .tab2 {
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 16px;
    height: 42px;
    cursor: pointer;
    li {
      height: 32px;
      line-height: 32px;
      box-sizing: border-box;
      background: #f6f7f9;
      border-radius: 16px 16px 16px 16px;
      text-align: center;
      font-size: 16px;
      float: left;
      color: #757575;
      padding: 0 24px;
      margin-right: 12px;
    }
    .active1 {
      font-size: 18px;
      font-weight: 400;
      background-color: #ed1b23;
      color: #fff;
    }
  }
  // 右侧保修 客服
  // .fix-right {
  //   cursor: pointer;
  //   width: 88px;
  //   height: 90px;
  //   border: 1px solid #ebebeb;
  //   position: fixed;
  //   z-index: 1000;
  //   right: 0;
  //   bottom: 266px;
  //   // top: 50%;
  //   // transform: translateY(-50%);
  //   .item1 {
  //     background-color: #ffffff;
  //   }
  //   .item2 {
  //     background-color: #ed1b23;
  //   }
  //   .item1,
  //   .item2 {
  //     height: 90px;
  //     text-align: center;
  //     box-sizing: border-box;
  //     padding-top: 9px;
  //     img {
  //       width: 24px;
  //       height: 24px;
  //     }
  //     p {
  //       color: #757575;
  //       font-size: 14px;
  //       margin-top: 12px;
  //     }
  //   }
  //   .item2 p {
  //     color: #ffffff;
  //   }
  // }
}
// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   height: 180px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
// .assderTc {
//   display: flex;
//   align-items: center;
//   .title {
//     padding-top: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 22px;
//     font-weight: bold;
//     color: #333333;
//     img {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       top: 32px;
//       right: 32px;
//     }
//   }
//   ::v-deep .el-dialog__header {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 0 !important;
//   }
//   ::v-deep .el-dialog__body {
//     padding: 0;
//   }
//   ::v-deep .el-dialog__footer {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 10px;
//   }
//   .ja-c {
//     height: 311px;
//     box-sizing: border-box;
//     padding-left: 214px;
//     padding-top: 52px;
//     p {
//       display: flex;
//       align-items: center;
//       margin-bottom: 32px;
//       img {
//         width: 30px;
//         height: 30px;
//       }
//       span {
//         font-size: 14px;
//         color: #333333;
//         margin-left: 16px;
//       }
//     }
//   }

//   .btnad {
//     width: 112px;
//     height: 40px;
//     background: rgba(246, 247, 249, 1);
//     border: 1px solid #ecedef;
//     opacity: 1;
//     border-radius: 20px;
//   }
//   .btnsad {
//     color: #333;
//     width: 112px;
//     height: 40px;
//     background: rgba(255, 195, 44, 1);
//     opacity: 1;
//     border-radius: 20px;
//   }
// }
</style>
